.csl-bg-primary {
    background-color: orange;
}

.csl-bg-secondary {
    background-color: rgb(230,220,60);
}

@media(max-width: 767px) {
    .csl-portrait {
        border: solid 5px orange;
        border-radius: 50% / 14%;
        margin-right: auto;
        margin-left: auto;
        margin-top: 1.25rem;
        margin-bottom:  1.25rem;
        width: 100%;
        
    }
}
@media(min-width: 768px) {
    .csl-portrait {
        border: solid 3px orange;
        border-radius: 10%;
        width: 100%;
        margin-top: 0.75rem;
        margin-bottom: 0.75rem;
        
    }
}